import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Appoinment() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Summer Camp Registration">
      <div className="md:max-w-full w-full ">
        <TransparentHeader
          headertitle="Summer Camp Registration"
          Subheader="Summer Camp Registration"
          bgimg={`${rpdata?.gallery?.[15]}`}
        />

        <div className="bg-[url('https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Imagenes%20decoraciones%20angel%20massage%2Ffondo%20utiles%20escolares.jpg?alt=media&token=e8f7df8b-0d3e-4d3c-807c-ca4c38a7c8a5')] bg-repeat flex justify-center flex-col md:flex-row items-center space-x-0 w-[100%] mx-auto px-4 py-10 lg:px-4">
            <div className="backdrop-blur-xl bg-white/30 p-4 px-25">
                <div class="elfsight-app-e728a685-88c8-42f1-9014-4706a3154b5c"></div>
            </div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Appoinment;
